<template>
  <div class="joinUs">
    <Head :type=3></Head>
    <Carousel></Carousel>
      <div class="joinUs_con">
        <div class="img_con">
            <div class="wow slideInRight" :class="['jiaru',tabBg ? '' : 'jiaru1']" @click="recruit">
                <span style="margin-right:25px">招聘</span>
                <span>RECRUITMENT</span>
                <img v-if="tabBg" class="img" src="../assets/img/fuhao.png" alt="">
            </div>
            <div class="wow slideInLeft" :class="['jiameng',!tabBg ? '' : 'jiameng1']" @click="join">
                <span style="margin-right:25px">加盟</span>
                <span>JOIN US</span>
                <img v-if="!tabBg" class="img" src="../assets/img/fuhao.png" alt="">
            </div>
        </div>
        <div class="jiaru_con" v-for="(item,index) in joinList" :key="index">
            <p class="title">{{item.title}}</p>
            <div v-html="item.content"></div>
            <!-- <div v-for="(ite,indexA) in item.subheadList" :key="indexA">
                <p class="subhead">{{ite.subhead}}</p>
                <div v-for="(it,indexB) in ite.requirement" :key="indexB">
                    <p class="requirement">{{indexB+1}}.{{it}}</p>
                </div>
            </div> -->
            <img v-if="(index+1)%2 != 0" src="../assets/img/xian.png" alt="" style="width:100%;margin:78px 0px">
        </div>
      </div>
      <div class="pagination">
        <el-pagination
        background
        prev-text="上一页"
        next-text="下一页"
        layout="prev, pager, next"
        :total=total
        :current-page="current"
        :page-size="size"
        @current-change="handleCurrentChange">
        </el-pagination>
      </div>
    <RightSide></RightSide>
    <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import Head from "@/components/Head.vue";
import Footer from "@/components/Footer.vue";
import Carousel from "@/components/Carousel.vue";
import RightSide from "@/components/RightSide.vue";
import { getJoin } from "@/api/common";
export default {
  name: "joinUs",
  components: {
    Head,
    Footer,
    Carousel,
    RightSide
  },
  data() {
    return {
      tabBg: true,
      current:  1,
      size: 5,
      joinType: 1,
      total: 0,
      joinList: [],
    }
  },
  methods: {
    goMore() {
      this.$router.push('/homeERP')
    },
    recruit() {
      this.tabBg = true
      this.joinType = 1
      this.current = 1
      this.getJoin()
    },
    join() {
      this.tabBg = false
      this.joinType = 2
      this.current = 1
      this.getJoin()
    },
    handleCurrentChange(val) {
      this.current = val
      this.getJoin()
    },
        //加入分页
    getJoin() {
      let params = {
        current: this.current,
        size: this.size,
        joinType: this.joinType
      };
      getJoin(params).then((res) => {
        if (res.data.code == 200) {
          this.total = res.data.data.total
          this.joinList = res.data.data.records
        } else {

        }
      });
    },
  },
  mounted() {
      this.getJoin()
  },
};
</script>
<style scoped lang="less">
.joinUs {
    overflow: hidden;
  .joinUs_con {
    width: 1280px;
    margin: 0px auto;
    overflow: hidden;
    .img_con {
        width: 100%;
        height: 80px;
        display: flex;
        margin-top: 34px;
        margin-bottom: 61px;
        .jiaru,.jiameng {
            width: 50%;
            height: 80px;
            text-align: center;
            line-height: 80px;
            font-size: 32px;
            background-color: #989898;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #FFFEFE;
            cursor: pointer;
        }
        .jiaru {
            background: url(../assets/img/jiaru.png) no-repeat;
            background-size:100% 100%;
        }
        .jiameng {
            background: url(../assets/img/jiameng.png) no-repeat;
            background-size:100% 100%;
        }
        .jiaru1 {
            background: url(../assets/img/jiaru1.png) no-repeat;
            background-size:100% 100%;
            color: #333333;
        }
        .jiameng1 {
            background: url(../assets/img/jiameng1.png) no-repeat;
            background-size:100% 100%;
            color: #333333;
        }
        .img {
            margin-left: 10px;
            position: relative;
            top: 12px;
        }
    }
    .jiaru_con {
        text-align: left;
        .title {
            font-weight: bold;
            font-size: 30px;
            color: #008CD7;
            margin-bottom: 60px;
            font-family: MicrosoftYaHei-Bold;
        }
        .subhead {
            line-height: 50px;
            color: #008CD7;
            font-size: 22px;
            font-family: SourceHanSansCN-;
        }
        .requirement {
            color: #333333;
            font-size: 22px;
            line-height: 50px;
            font-family: SourceHanSansCN-;
        }
    }
  }
  .pagination {
    width: 100%;
    text-align: center;
    margin-bottom: 44px;
    margin-top: 61px;
  }
}
</style>
<style lang="less">
.joinUs {
  .el-pagination.is-background .btn-next{
     padding: 0px 10px;
  }
  .el-pagination.is-background .btn-prev{
     padding: 0px 10px;
  }
}
</style>
